export const CACHE = {
  USER_PROMOS: "userPromos",
  GET_STATES: "getStates",
  GET_PENDING_AUTO_APPROVALS: "getPendingAutoApprovals",
  APPROVE_PENDING_SHIFT_REQUESTS: "approvePendingShiftRequests",
  REJECT_PENDING_SHIFT_REQUESTS: "rejectPendingShiftRequests",
  GET_ENTERPRISES: "getEnterprises",
  GET_ENTERPRISE_USER: "getEnterpriseUser",
  GET_ENTERPRISE_HOSPITALS: "getEnterpriseHospitals",
  GET_HOSPITAL_GROUPS: "getHospitalGroups",
  GET_HOSPITAL_GROUP_ADMINS: "getHospitalGroupAdmins",
  GET_HOSPITAL_CONTACT_OWNER: "getHospitalContactOwner",
  GET_HOSPITALS_SUMMARY_BY_GROUP: "getHospitalsSummaryByGroup",
  GET_ALL_INVOICES: "getAllInvoices",
  GET_VET_ANALYTICS: "getVetAnalytics",
  GET_HOSPITAL_RATINGS: "getHospitalRatings",
  PROMO_ELIGIBLE_USERS: "promoEligibleUsers",
  PROMO_DETAILS_BY_ID: "promoDetailsById",
  CREATE_PROMO: "createPromo",
  UPDATE_PROMO: "updatePromo",
  CONTACT_OWNER: "contactOwner",
  SIGN_IC_AGREEMENT: "signICAgreement",
  ACCEPT_TERMS: "acceptTerms",
  GET_USER_INFO: "getUserInfo",
  RESTRICTED_EMAIL_DOMAINS: "restrictedEmailDomains",
  REFERRAL_PROMO_AMOUNT: "referralPromoAmount",
  REFERRAL_CAMPAIGNS: "referralCampaigns",
  MARKET_ZONES: "marketZones",
  AVAILABLE_HOSPITAL_SHIFTS: "hospitalAvailableShifts",
  SHIFT: "shift",
  GET_NOTIFICATION_SETTINGS: "getNotificationSettings",
  GET_NOTIFICATION_ATTRIBUTES: "getNotificationAttributes",
  GET_FAVORITES: "getFavorites",
  GET_BLOCKED_HOSPITALS: "getBlockedHospitals",
  RATING_SUMMARY: "ratingSummary",
  PROMO_TRACKING_BY_PROMO_ID: "promoTrackingByPromoId",
  GET_VET_SETTING_INFORMATION: "getVetSettingInformation",
  GET_MARKET_STATUS: "getMarketStatus",
  GET_LICENSE_DATA: "getLicenseData",
  GET_CONTACT_OWNER_BY_ID: "getContactOwnerById",
  FEATURE_FLAG_ENABLED: "featureFlagEnabled",
  FEATURE_FLAG_VARIANT_KEY: "featureFlagVariantKey",
  GET_CONTACT_DETAILS: "getContactDetails",
  GET_HOSPITAL_USERS: "getHospitalUsers",
  GET_HOSPITAL_PAYMENT_TERMS: "getHospitalPaymentTerms",
  GET_HOSPITAL_TABLE_LOGS: "getHospitalTableLogs",
  BOOKMARKS: "bookmarks",
  ALL_VETS: "allVets",
  STRIPE_SETUP_INTENT: "stripeSetupIntent",
  GET_STRIPE_SETUP_INTENTS: "getStripeSetupIntents",
  WORKED_HOSPITALS: "workedHospitals",
  SET_AVAILABLE_DAY: "setAvailableDay",
  GET_AVAILABLE_DAYS: "getAvailableDays",
  MILESTONE_STATUS: "milestoneStatus",
} as const;

export const ADMIN_CACHE = {
  ALL_PROMOS: "allPromos",
  PAST_PROMOS: "pastPromos",
  CONTACT_OWNERS: "api/admin/contactOwners",
  PENDING_VET_SHIFTS: "api/admin/pendingShifts",
  PAGINATED_USERS: "api/admin/users",
  SEARCH_HISTORY: "api/user/searchHistory",
};

import { ProviderType } from "./provider";

export const VET_ROUTES = {
  SCHEDULE: "/vet/schedule",
  SCHEDULE_SHIFT_DETAILS: "/vet/schedule/shifts/:shiftGroupId",
  SHIFTS: "/vet",
  SHIFT_DETAILS: "/vet/shifts/:shiftGroupId",
  EARNINGS: "/vet/history",
  PROFILE: "/vet/editprofile",
  PROMOS: "/vet/promos",
  REFER: "/vet/referuser",
  POLICIES: "/vet/policies",
} as const;

export const TECH_ROUTES = {
  SCHEDULE: "/tech/schedule",
  SCHEDULE_SHIFT_DETAILS: "/tech/schedule/shifts/:shiftGroupId",
  SHIFTS: "/tech",
  SHIFT_DETAILS: "/tech/shifts/:shiftGroupId",
  EARNINGS: "/tech/history",
  PROFILE: "/tech/editprofile",
  PROMOS: "/tech/promos",
  REFER: "/tech/referuser",
  POLICIES: "/tech/policies",
} as const;

// Common routes that don't need provider type
export const COMMON_ROUTES = {
  TOOLKIT: "https://roo.vet/veterinary-resources",
  SETTINGS: "/manageSettings",
  ADMIN: "/admin",
} as const;

// For backward compatibility during migration
export const ROUTES = {
  SCHEDULE: (providerType: ProviderType) => `/${providerType.toLowerCase()}/schedule`,
  SCHEDULE_SHIFT_DETAILS: (providerType: ProviderType) =>
    `/${providerType.toLowerCase()}/schedule/shifts/:shiftGroupId`,
  SHIFTS: (providerType: ProviderType) => `/${providerType.toLowerCase()}`,
  SHIFT_DETAILS: (providerType: ProviderType) =>
    `/${providerType.toLowerCase()}/shifts/:shiftGroupId`,
  EARNINGS: (providerType: ProviderType) => `/${providerType.toLowerCase()}/history`,
  PROFILE: (providerType: ProviderType) => `/${providerType.toLowerCase()}/editprofile`,
  PROMOS: (providerType: ProviderType) => `/${providerType.toLowerCase()}/promos`,
  REFER: (providerType: ProviderType) => `/${providerType.toLowerCase()}/referuser`,
  POLICIES: (providerType: ProviderType) => `/${providerType.toLowerCase()}/policies`,
  ...COMMON_ROUTES,
} as const;

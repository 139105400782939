import { Theme } from "@RooUI";
import { QueryClientProvider } from "@tanstack/react-query";
import posthog from "posthog-js";
import React, { Dispatch, Suspense, createContext, lazy } from "react";
import { connect } from "react-redux";
import { Route, RouteComponentProps, withRouter } from "react-router-dom";
import { AnyAction } from "redux";
import _refiner from "refiner-js";

import LicenseViewer from "./Admin/LicenseViewer/LicenseViewer";
import { SocketSource, initSocketConnection } from "./Common/Chat/service";
import { ModalProvider } from "./Common/Modals/context/ModalContext";
import { NavigationReasonContainer } from "./Common/NavigationReason/components/NavigationReasonContainer";
import NotificationSettingsContainer from "./Common/NotificationSettings/NotificationSettingsContainer";
import PoliciesPage from "./Common/Policies/PoliciesPage";
import ProtectedRoute from "./Common/ProtectedRoute";
import { RooRoute } from "./Common/RooRoute";
import { ToastContainer } from "./Common/Toasts/components/ToastContainer";
import { ToastContainerRedux } from "./Common/Toasts/components/ToastContainerRedux";
import { ToastProvider } from "./Common/Toasts/context/ToastContext";
import { routeUser } from "./Common/routeUser";
import { initSession } from "./Common/sessionUtils";
import { EventSignUpContainer, EventSignUpRedirectContainer } from "./EventSignUp/components";
import SettingsPage from "./Hospital/ManageSettings/SettingsPage";
import { LogOut } from "./LogOut/LogOut";
import * as LoginActionCreators from "./Login/Action/LoginActions";
import Login from "./Login/Login";
import { ForgotPasswordComponent as ForgotPassword } from "./Login/forgotPassword/components/ForgotPasswordComponent";
import { ResetPasswordContainer as ResetPassword } from "./Login/forgotPassword/components/ResetPasswordContainer";
import Unsubscribe from "./Unsubscribe/Unsubscribe";
import { queryClient } from "./api/reactQueryClient";
import * as UserTypes from "./constants/UserTypeConstants";
import * as ErrorMessages from "./constants/errorMessage";
import * as userStatusTypes from "./constants/userStatusTypes";
import { AuthUtility } from "./helpers/AuthUtility";
import { setLoaderAction } from "./loader/actions/loaderAction";
import Loader from "./loader/components/loaderComponent";
import { UserSuspendedModal } from "./modalBodies/UserSuspendedModal";
import "./static/css/main-spa.scss";
import { RootState } from "./store";
import { TaskProvider } from "./taskContext";
import { SessionTracker } from "./tracking/components/SessionTracker";
import { Event, EventTracker } from "./tracking/service/EventTracker/EventTrackerService";
import { VetTechDashboardProvider } from "./vetTechDashboard/VetTechDashboardContext";

const AdminContainer = lazy(() => import("./Admin/AdminContainer"));
const RooSuperUserContainer = lazy(() => import("./Admin/RooSuperUserSelectUser"));
const RegisterRooInternalUser = lazy(() =>
  // eslint-disable-next-line promise/prefer-await-to-then
  import("./Registration/RegistrationRooInternalUser/RegistrationRooInternalUserContainer").then(
    (module) => ({ default: module.RegistrationRooInternalUser })
  )
);
const RegisterEnterpriseHospitalUser = lazy(async () => {
  const module = await import(
    "./Registration/RegistrationEnterprise/RegistrationEnterpriseContainer"
  );
  return { default: module.RegistrationEnterprise };
});
const RegistrationHospital = lazy(
  () => import("./Registration/RegistrationHospital/RegistrationHospitalContainer")
);
const RegisterStudent = lazy(
  () => import("./Registration/RegistrationStudent/RegistrationStudentContainer")
);
const RegisterNew = lazy(() => import("./RegistrationNew/RegistrationNew"));
const Enterprise = lazy(() => import("./Enterprise/EnterpriseContainer"));
const Hospital = lazy(() => import("./Hospital/HospitalContainer"));
const Vet = lazy(() => import("./vet/VetContainer"));
const Tech = lazy(() => import("./tech/TechContainer"));
const RooUniStudent = lazy(() => import("./student/StudentContainer"));

export const RooContext = createContext({ isPendingStatus: false, isNonResponsiveStatus: false });

type AppProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

type AppState = {
  zeLoaded: boolean;
  addShiftDropdownClassName: string;
  authUserTypeId: number | null;
};

class App extends React.Component<AppProps, AppState> {
  private isRoutingDone: boolean = false;
  private statusContextValue: { isPendingStatus: boolean; isNonResponsiveStatus: boolean } = {
    isPendingStatus: false,
    isNonResponsiveStatus: false,
  };

  constructor(props: AppProps) {
    super(props);

    if (props.location.search) {
      // Set redirectUrl in login.state if query string in url.
      // This is required in case we are coming from outside (email link) to
      // application and we are not logged in.
      // So we need to redirect again to the original email link.
      props.setRedirect(props.location.pathname + props.location.search);
    }

    this.state = {
      zeLoaded: false,
      addShiftDropdownClassName: "dropdown-menu dropdown-menu-right",
      authUserTypeId: null,
    };
  }

  componentDidMount() {
    initSocketConnection(SocketSource.APP_INIT);
    initSession();

    const { pathname, search } = window.location;

    routeUser({
      ...this.props,
      redirectUrl: search ? pathname + search : null,
      isPostLogin: false,
      setIsRoutingDone: (value: boolean) => (this.isRoutingDone = value),
    });

    // eslint-disable-next-line promise/prefer-await-to-then
    AuthUtility.getAuthContext().then((authContext) => {
      this.setState({ authUserTypeId: authContext ? authContext.userTypeId : null });
    });
  }

  componentDidUpdate(prevProps: AppProps) {
    const userTypeId = this.props.userTypeId;
    const isHospital = userTypeId === UserTypes.HOSPITAL_USER_TYPE;
    const isAuthorised = this.props.isAuthorisedUser;

    const body = document.getElementById("root");

    if (body) {
      body.onclick = this.onClickBody;
    }

    const {
      isSessionInfoFetched,
      isRegistrationComplete,
      vetRatingCount,
      techRatingCount,
      history,
      isPendingVetRatings,
      isPendingTechRatings,
      loginMessage,
      isActive,
      termsAccepted,
      hasICAgreementSkipped,
      userId,
      firstName,
      lastName,
      email,
      approvedOnDate,
      isEligibleForNPS,
      fullName,
      location,
    } = this.props;

    // Send pageview event to posthog on page change (excluding the first page load, which is already captured).
    if (location.pathname !== prevProps?.location?.pathname) {
      posthog.capture("$pageview");
    }

    const { pathname } = history.location;

    if (pathname === "/unsubscribe") {
      // Skip all logic for email unsubscribe page.
      return;
    }

    const localStorageRedirectUrl = localStorage.getItem("redirectUrl");
    const redirectUrl = this.props.redirectUrl || localStorageRedirectUrl;
    const isVet = userTypeId === UserTypes.VET_USER_TYPE;
    const isTech = userTypeId === UserTypes.TECH_USER_TYPE;

    // Reload page for demo users.
    const isReloadAfterDemo = localStorage.getItem("isReloadAfterDemo");

    if (isReloadAfterDemo && (isVet || isTech)) {
      localStorage.removeItem("isReloadAfterDemo");
      history.push(isVet ? "/vet" : "/tech");
    }

    // For existing CA vets, mandate IC contract.
    const userStatus = isActive ? isActive : localStorage.getItem("isActive");
    const isPending = userStatus == userStatusTypes.PENDING;
    const isBlocked = userStatus == userStatusTypes.BLOCKED;

    // Refiner.io survey platform
    _refiner("setProject", window.RooConfig.REFINER_ID);
    if (userId && email) {
      _refiner("identifyUser", {
        id: userId,
        email: email,
        name: firstName || lastName ? `${firstName} ${lastName}` : fullName,
        approved_on_date: new Date(approvedOnDate),
        user_type_id: userTypeId,
        is_eligible_for_nps: isEligibleForNPS,
      });
    }

    if (
      isSessionInfoFetched &&
      isTech &&
      parseInt(isRegistrationComplete) === 1 &&
      pathname !== "/tech/icAgreement" &&
      !termsAccepted &&
      !hasICAgreementSkipped &&
      !isPending &&
      !isBlocked
    ) {
      history.push("/tech/icAgreement");
    }

    if (isAuthorised && loginMessage && loginMessage.statusCode === 200 && !this.isRoutingDone) {
      routeUser({
        ...this.props,
        redirectUrl,
        isPostLogin: true,
        setIsRoutingDone: (value: boolean) => (this.isRoutingDone = value),
      });
    } else if (!isAuthorised) {
      this.isRoutingDone = false;
    }

    const isDoVetRatings = isPendingVetRatings && vetRatingCount !== null && vetRatingCount <= 15;
    const isDoTechRatings =
      isPendingTechRatings && techRatingCount !== null && techRatingCount <= 15;
    const isRooSuperUser = this.state.authUserTypeId === UserTypes.ROO_SUPER_USER_TYPE;

    if (isHospital && isAuthorised) {
      if (
        pathname !== "/hospital" &&
        pathname !== "/hospital/externships" &&
        pathname !== "/hospital/postExternship"
      ) {
        if ((isDoVetRatings || isDoTechRatings) && !isRooSuperUser) {
          history.push("/hospital");
        }
      }
    }

    if (window.zE) {
      // Setting the zendesk chat widget's zindex one point lower to that of
      // right-panel-container which is 100 so that it wont overlap with chat's
      // send btn.
      window.zE("messenger:set", "zIndex", 99);

      if (!this.state.zeLoaded && isActive) {
        this.setState({ zeLoaded: true });
      }
    }
  }

  onClickBody = () => {
    const addShiftDropdown = document.getElementById("add-shift-dropdown");
    const currentClassName = addShiftDropdown?.className;

    if (currentClassName && currentClassName !== this.state.addShiftDropdownClassName) {
      this.setState({ addShiftDropdownClassName: currentClassName }, () => {
        if (currentClassName.indexOf("show") > -1) {
          EventTracker.send({
            eventName: Event.Name.HOSPITAL_ADD_SHIFT,
            eventType: Event.Type.CLICK,
            entityType: Event.Entity.HOSPITAL,
            entityId: this.props.hospitalId,
            context: {
              shiftType: null,
              location: "add_shift_navbar",
            },
          });
        }
      });
    }
  };

  render() {
    // @TODO: `isShowUserSuspendedPopup` may not be getting set anywhere.
    // Originally it was set unsafely in the fetch interceptor, but that has
    // since been removed. It might make sense to set it in the login saga.
    const { loader, isShowUserSuspendedPopup, hospitalId, userId, isActive } = this.props;
    const modalMessage = isShowUserSuspendedPopup ? ErrorMessages.LOGIN_SUSPENDED_MESSAGE : "";

    // Temporary solution to pass userStatusId to RooContext until login is
    // refactored from redux/local storage.
    const userStatusId = isActive ?? localStorage.getItem("isActive");
    const isNonResponsiveStatus = parseInt(userStatusId) === userStatusTypes.NONRESPONSIVE;
    const isPendingStatus =
      parseInt(userStatusId) === userStatusTypes.PENDING || isNonResponsiveStatus;

    if (
      this.statusContextValue.isPendingStatus !== isPendingStatus ||
      this.statusContextValue.isNonResponsiveStatus !== isNonResponsiveStatus
    ) {
      this.statusContextValue = { isPendingStatus, isNonResponsiveStatus };
    }

    return (
      <>
        <SessionTracker />
        <QueryClientProvider client={queryClient}>
          <ToastProvider>
            <ModalProvider>
              <RooContext.Provider value={this.statusContextValue}>
                <TaskProvider hospitalId={hospitalId} userId={userId}>
                  <Theme />
                  <ToastContainerRedux />
                  <ToastContainer />
                  <NavigationReasonContainer />
                  <UserSuspendedModal
                    smModal={true}
                    showModal={isShowUserSuspendedPopup}
                    modalMessage={modalMessage}
                    onCloseHandler={this.props.hideSuspendedUserPopup}
                  />
                  <Loader showLoader={loader} />
                  <RooRoute path="/login" title="Log In" component={Login} />
                  <RooRoute path="/logout" title="Log Out" component={LogOut} />
                  <RooRoute
                    path="/forgotPassword"
                    title="Forgot Password"
                    component={ForgotPassword}
                  />
                  <Route path="/resetpassword" component={ResetPassword} />
                  <Route path="/unsubscribe" component={Unsubscribe} />
                  <Suspense fallback={<Loader showLoader={true} />}>
                    <RooRoute
                      path="/registerHospital"
                      title="Create Hospital Account"
                      component={RegistrationHospital}
                    />
                    <Route
                      path="/registerEnterpriseHospitalUser"
                      component={RegisterEnterpriseHospitalUser}
                    />
                    <RooRoute path="/register" component={RegisterNew} title="Create Account" />
                    <Route
                      path="/registerEnterpriseHospital"
                      component={RegisterEnterpriseHospitalUser}
                    />
                    <Route path="/registerRooInternalUser" component={RegisterRooInternalUser} />
                    <RooRoute
                      path="/registerStudent"
                      title="Create Account"
                      component={RegisterStudent}
                    />
                    <ProtectedRoute path="/enterprise" component={Enterprise} />
                    <ProtectedRoute path="/hospital" component={Hospital} />
                    <VetTechDashboardProvider>
                      <ProtectedRoute path="/vet" component={Vet} />
                      <ProtectedRoute path="/tech" component={Tech} />
                    </VetTechDashboardProvider>
                    <ProtectedRoute path="/student" component={RooUniStudent} />
                    <ProtectedRoute path="/admin" component={AdminContainer} title="Admin" />
                    <ProtectedRoute path="/superuser" component={RooSuperUserContainer} />
                    <ProtectedRoute
                      path="/notification"
                      component={NotificationSettingsContainer}
                    />
                    <ProtectedRoute path="/policies" component={PoliciesPage} />
                    <ProtectedRoute path="/manageSettings" component={SettingsPage} />
                    <ProtectedRoute path="/licenseViewer" component={LicenseViewer} />
                    <Route path="/e/:eventSlug" component={EventSignUpContainer} />
                    <Route path="/event/:eventSlug" component={EventSignUpRedirectContainer} />
                  </Suspense>
                </TaskProvider>
              </RooContext.Provider>
            </ModalProvider>
          </ToastProvider>
        </QueryClientProvider>
      </>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    isSessionInfoFetched: state.login.isSessionInfoFetched,
    isRegistrationComplete: state.login.isRegistrationComplete,
    stateId: state.login.stateId,
    termsAccepted: state.login.termsAccepted,
    hasICAgreementSkipped: state.login.hasICAgreementSkipped,
    loader: state.loader.loader,
    isPendingTechRatings: state.hospital.isPendingTechRatings,
    techRatingCount: state.shiftRatingByHospital.techRatingCount,
    isPendingVetRatings: state.hospital.isPendingVetRatings,
    vetRatingCount: state.shiftRatingByHospital.vetRatingCount,
    isAuthorisedUser: state.login.isAuthorisedUser,
    isShowUserSuspendedPopup: state.login.isShowUserSuspendedPopup,
    vetRegistrationPageNo: state.login.vetRegistrationPageNo,
    techRegistrationPageNo: state.login.techRegistrationPageNo,
    studentRegistrationPageNo: state.login.studentRegistrationPageNo,
    hospitalRegistrationPageNo: state.login.hospitalRegistrationPageNo,
    newRegistrationPage: state.login.newRegistrationPage,
    loginMessage: state.login.loginMessage,
    userTypeId: state.login.userTypeId,
    redirectUrl: state.login.redirectUrl,
    studentGradYear: state.login.studentGradYear,
    isActive: state.login.isActive,
    isShowOnboarding: state.login.isShowOnboarding,
    firstName: state.login.firstName,
    lastName: state.login.lastName,
    phoneNumber: state.login.phoneNumber,
    fullName: state.login.fullName,
    email: state.login.email,
    userId: state.login.userId,
    approvedOnDate: state.login.approvedOnDate,
    isEligibleForNPS: state.login.isEligibleForNPS,
    hospitalId: state.login.hospitalId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    setLoader: () => dispatch(setLoaderAction()),
    setRedirect: (value: string) => dispatch(LoginActionCreators.setRedirect(value)),
    hideSuspendedUserPopup: () => dispatch(LoginActionCreators.hideSuspendedUserPopup()),
    setSession: () => dispatch(LoginActionCreators.setSessionOnRefresh()),
    clearRedirect: () => dispatch(LoginActionCreators.clearRedirect()),
  };
};

export const ConnectedApp = withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

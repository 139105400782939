import React, { useRef } from "react";

import { ClaimPromoCard } from "./ClaimPromoCard";
import { PromoReminderCard } from "./PromoReminderCard";
import { NotificationCard, NotificationCardPriority } from "./types";
import { usePromoManagement } from "./usePromoManagement";
import { MicroDepositStatusAlert } from "@Roo/Common/AddBankModal/MicroDepositStatusAlert";
import { useRequiredAuthorizedUser } from "@RooBeta/hooks";
import { useStripeVerificationStatus } from "@Roo/hooks/useStripeVerificationStatus";

import { styled } from "styled-components";

/**
 * Global notification card that displays a claim promo card if the user has eligible promos.
 * Can be extended to display other types of cards in the future.
 * @returns null if the user does not have eligible promos, otherwise a ClaimPromoCard.
 */
export const GlobalNotificationCard = () => {
  const {
    vetId,
    techId,
    provider: { providerType },
  } = useRequiredAuthorizedUser();
  const { hasEligiblePromos, promoReminder } = usePromoManagement();
  const { isVerificationReady, hostedVerificationUrl } = useStripeVerificationStatus({
    userType: providerType === "VET" ? "vet" : "tech",
  });
  const ref = useRef<HTMLDivElement>(null);

  const availableCards: NotificationCard[] = [
    promoReminder && {
      type: "promoReminder",
      priority: NotificationCardPriority.PROMO_REMINDER,
      component: <PromoReminderCard promo={promoReminder} />,
    },
    hasEligiblePromos && {
      type: "eligiblePromo",
      priority: NotificationCardPriority.ELIGIBLE_PROMO,
      component: <ClaimPromoCard />,
    },
    isVerificationReady &&
      hostedVerificationUrl && {
        type: "microDepositStatus",
        priority: NotificationCardPriority.MICRO_DEPOSIT_STATUS,
        component: (
          <MicroDepositStatusAlert
            hostedVerificationUrl={hostedVerificationUrl}
            providerType={providerType === "VET" ? "vet" : "tech"}
            providerId={vetId ?? techId ?? 0}
          />
        ),
      },
  ].filter(Boolean) as NotificationCard[];

  const cardToShow = availableCards.sort((a, b) => a.priority - b.priority)[0];

  if (!cardToShow) return null;

  return (
    <GlobalNotificationCardContainer ref={ref} data-testid="globalNotificationCard">
      {cardToShow.component}
    </GlobalNotificationCardContainer>
  );
};

const GlobalNotificationCardContainer = styled.div`
  width: 100%;
`;

export type RGB = `rgb(${number}, ${number}, ${number})`;
export type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HEX = `#${string}`;
export type Color = RGB | RGBA | HEX;

export const purpleBackground = "#EDEAF5";
export const purpleLightest = "#D9D4EA";
export const purpleLighter = "#B3A9D4";
export const purpleLight50 = "#EDEAF5";
export const purpleLight100 = "#D9D4EA";
export const purpleLight200 = "#B3A9D4";
export const purpleLight300 = "#8D7EBF";
export const purpleLight400 = "#6753A9";
export const purpleBase = "#412894";
export const purpleDark600 = "#311E6F";

export const greenBackground = "#E6F2EE";
export const greenBase = "#007A4C";
export const greenDark600 = "#005C39";

export const blueLightest = "#DBDFFA";
export const blueLight300 = "#929FEF";
export const blueBase = "#495FE4";
export const blueDark600 = "#3747AB";

export const tealLightest = "#D2EFF0";
export const tealLight300 = "#79CFD1";
export const tealBase = "#1FAFB2";
export const tealDark600 = "#178386";

export const grapeBase = "#C745D5";

export const mustardBase = "#FFA700";

export const grayWhite = "#FFFFFF";
export const grayLightest = "#F9F9F9";
export const grayBackground = "#EBEBEB";
export const grayLight50 = "#EBEBEB";
export const grayLight100 = "#D6D6D6";
export const grayLight200 = "#ACACAD";
export const grayLight300 = "#767676";
export const grayLight400 = "#59595A";
export const grayBase = "#303031";

export const redBackground = "#FDE8E8";
export const redBase = "#E81616";

export const yellowBackground = "#FDF9E9";
export const yellowBase = "#E7C21C";

export const disabledTextColor = "#838383";
export const disabledTextDark = "#59595A";

//Box Shadows

export const blueLight300Shadow = "rgba(146, 159, 239, 0.2)";
export const purpleBaseShadow = "rgba(65, 40, 148, 0.2)";
export const blueDark600Shadow = "rgba(55, 71, 171, 0.2)";

//deprecated

export const purpleDark700 = "#21144A";
export const purpleDark800 = "#100A25";
export const purpleDarker = "#100A25";
export const purpleDarkest = "#07040F";
export const greenLightest = "#CCE4DB";
export const greenLighter = "#99CAB7";
export const greenLight300 = "#66AF94";
export const greenLight400 = "#339570";
export const greenDark700 = "#003D26";
export const greenDarker = "#001F13";
export const greenDarkest = "#000C08";
export const blueBackground = "#EDF0FD";
export const blueLighter = "#B6BFF4";
export const blueLight100 = "#DBDFFA";
export const blueLight400 = "#6D7FE9";
export const blueDark700 = "#253072";
export const blueDarker = "#121839";
export const blueDarkest = "#070A17";
export const grapeBackground = "#FDEFFF";
export const grapeLightest = "#FACBFF";
export const grapeLighter = "#F6A7FF";
export const grapeLight300 = "#F383FF";
export const grapeLight400 = "#E85CF7";
export const grapeDark600 = "#A631B3";
export const grapeDark700 = "#862191";
export const grapeDarker = "#66146F";
export const grapeDarkest = "#460A4D";
export const tealBackground = "#E9F8F8";
export const tealLighter = "#A5DFE0";
export const tealLight100 = "#D2EFF0";
export const tealLight400 = "#4CBFC1";
export const tealDark700 = "#105859";
export const tealDarker = "#082C2D";
export const tealDarkest = "#031212";
export const mustardBackground = "#FFF6E5";
export const mustardLightest = "#FFE6B7";
export const mustardLighter = "#FFD68A";
export const mustardLight300 = "#FFC65C";
export const mustardLight400 = "#FFB72E";
export const mustardDark600 = "#D68C00";
export const mustardDark700 = "#AD7200";
export const mustardDarker = "#855700";
export const mustardDarkest = "#5C3C00";
export const grayLighter = "#ACACAD";
export const grayDark600 = "#242425";
export const grayDark700 = "#181819";
export const grayDarker = "#0C0C0C";
export const grayDarkest = "#050505";
export const redLightest = "#FAD0D0";
export const redLighter = "#F6A2A2";
export const redLight50 = "#FDE8E8";
export const redLight300 = "#F17373";
export const redLight400 = "#ED4545";
export const redDark600 = "#AE1111";
export const redDark700 = "#740B0B";
export const redDarker = "#3A0606";
export const redDarkest = "#170202";
export const yellowLightest = "#FAF3D2";
export const yellowLighter = "#F5E7A4";
export const yellowLight300 = "#F1DA77";
export const yellowLight400 = "#ECCE49";
export const yellowDark600 = "#AD9215";
export const yellowDark700 = "#74610E";
export const yellowDarker = "#3A3107";
export const yellowDarkest = "#171303";

// These do NOT follow the correct naming convention.
export enum LEGACY_FEATURE_FLAGS {
  NEW_PROMO_PAGE = "new-promo-page",
  IS_REQUIRE_DEA_LICENSE = "is-require-dea-license",
  DISABLE_ADD_TECH_SHIFT = "disable-add-tech-shift",
  CHAT_FEATURE = "chat-feature",
  SHIFT_RECOMMENDATION_PAGE = "shift-recommendation-page",
  SHARE_SEARCH_SHIFTS = "share-search-shifts",
  HOSPITAL_PROMO_TECH_SHIFTS = "hospital-promo-tech-shifts",
}

export enum NEW_FEATURE_FLAGS {
  HOSPITAL_FAVORITE_PROMPT = "dec-2024-5-star-favorite-prompt",
  FAVORITE_HOSPITAL_MODAL = "dec-2024-favorite-hospital-modal",
  MANUAL_PROMO_PAYOUT = "aug-2024-manual-promo-payout",
  HOSPITAL_CUSTOM_PRICE = "aug-2024-hospital-custom-price",
  VET_BID_REASON = "aug-2024-vet-bid-reason",
  COUNTER_OFFER = "aug-2024-counteroffer",
  REQUEST_MORE_SHIFTS = "aug-2024-request-more-shifts",
  SHIFT_PRICING_SURVEY = "sep-2024-shift-pricing-survey",
  ONBOARDING_TASK_CENTER = "sep-2024-onboarding-task-center",
  SHIFT_CARD_REVAMP = "sep-2024-shift-card-revamp",
  MANUAL_CLAIM_PROMO = "sep-2024-manual-claim-promo",
  MANUAL_ADD_SHIFT_TO_PROMO = "sep-2024-manual-add-shift-to-promo",
  IMPROVE_FILTERS_POPOVER = "sep-2024-improve-filters-popover",
  SHIFT_SEARCH_PREFERENCES = "sep-2024-shift-search-preferences",
  MARS_NDA_AGREEMENT = "oct-2024-mars-nda",
  NEW_SEARCH_EXPERIENCE = "oct-2024-new-find-shifts-web",
  ADMIN_BOOKMARK = "nov-2024-admin-bookmark",
  FORCE_POLLING_TRANSPORT_FOR_CHAT = "dec-2024-force-polling-transport-for-chat",
  NEW_DESIGN_EXPERIMENT_RELEASE = "jan-2025-new-design-release",
  CURRENT_SITUATION_PROMPT = "jan-2025-current-situation-question",
  SHIFT_PROPOSAL = "jan-2025-shift-proposal",
  PUBLISH_PROMO = "jan-2025-publish-promo",
  AUTO_CANCEL_FLOW = "feb-2025-new-hospital-cancellation-flow",
  ADMIN_CANCELLED_SHIFTS = "feb-2025-admin-hospital-cancellation-flow",
}

export const FEATURE_FLAGS = { ...NEW_FEATURE_FLAGS, ...LEGACY_FEATURE_FLAGS };
export type FeatureFlagName = NEW_FEATURE_FLAGS | LEGACY_FEATURE_FLAGS;

export enum FEATURE_FLAG_VARIANTS {
  CONTROL = "control",
  TEST = "test",
}

export const MONTH_PREFIXES = [
  "jan",
  "feb",
  "mar",
  "apr",
  "may",
  "jun",
  "jul",
  "aug",
  "sep",
  "oct",
  "nov",
  "dec",
];

import { Dropdown } from "antd";
import { ItemType, MenuDividerType } from "antd/es/menu/hooks/useItems";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { clearAuthAndRedirect } from "@Roo/LogOut/LogOut";
import { RooIcon } from "@RooBeta/components";
import { useRequiredAuthorizedUser, useTracking } from "@RooBeta/hooks";

import { NAVIGATION_ITEMS } from "./navigationItems";
import { AccountDropdownContainer } from "./styles";
import { getTrackingOptions, handleBackToAdmin, handleFeedback } from "./util";

interface AccountDropDownProps {
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
  isOpen: boolean;
}

export const ContractorAccountDropDown = ({ setIsOpen, children }: AccountDropDownProps) => {
  const history = useHistory();

  const {
    provider: { providerType },
    isSuperUser,
  } = useRequiredAuthorizedUser();

  const withTracking = useTracking({
    providerType,
  });

  const handleNavigationItemClick = useCallback(
    (label: string, path: string, useNewTab = false) => {
      setIsOpen(false);
      withTracking(() => {}, getTrackingOptions(label));
      if (useNewTab) {
        window.open(path, "_blank");
      } else {
        history.push(path);
      }
    },
    [withTracking, setIsOpen, history]
  );

  const menuItems = [
    {
      key: "rate-new-search-experience",
      label: NAVIGATION_ITEMS.DESIGN.RATE.label,
      icon: <RooIcon icon={NAVIGATION_ITEMS.DESIGN.RATE.icon} size="l" />,
      onClick: handleFeedback,
      "data-testid": "rateNewSearchExperienceItem",
    },
    { type: "divider" } as MenuDividerType,
    {
      key: "profile",
      label: NAVIGATION_ITEMS.ACCOUNT.PROFILE.label,
      icon: <RooIcon icon={NAVIGATION_ITEMS.ACCOUNT.PROFILE.icon} size="l" />,
      onClick: () =>
        handleNavigationItemClick(
          "My profile",
          NAVIGATION_ITEMS.ACCOUNT.PROFILE.path(providerType)
        ),
      "data-testid": "myProfileItem",
    },
    providerType === "VET"
      ? {
          key: "promos",
          label: NAVIGATION_ITEMS.ACCOUNT.PROMOS.label,
          icon: <RooIcon icon={NAVIGATION_ITEMS.ACCOUNT.PROMOS.icon} size="l" />,
          onClick: () =>
            handleNavigationItemClick("Promos", NAVIGATION_ITEMS.ACCOUNT.PROMOS.path(providerType)),
          "data-testid": "promosItem",
        }
      : undefined,
    {
      key: "referuser",
      label: NAVIGATION_ITEMS.ACCOUNT.REFER.label,
      icon: <RooIcon icon={NAVIGATION_ITEMS.ACCOUNT.REFER.icon} size="l" />,
      onClick: () =>
        handleNavigationItemClick(
          "Refer & earn",
          NAVIGATION_ITEMS.ACCOUNT.REFER.path(providerType)
        ),
      "data-testid": "referUserItem",
    },
    {
      key: "policies",
      label: NAVIGATION_ITEMS.RESOURCES.POLICIES.label,
      icon: <RooIcon icon={NAVIGATION_ITEMS.RESOURCES.POLICIES.icon} size="l" />,
      onClick: () =>
        handleNavigationItemClick(
          "Policies",
          NAVIGATION_ITEMS.RESOURCES.POLICIES.path(providerType)
        ),
      "data-testid": "policiesItem",
    },
    {
      key: "resources",
      label: NAVIGATION_ITEMS.RESOURCES.TOOLKIT.label,
      icon: <RooIcon icon={NAVIGATION_ITEMS.RESOURCES.TOOLKIT.icon} size="l" />,
      onClick: () =>
        handleNavigationItemClick(
          "Relief Resource Toolkit",
          NAVIGATION_ITEMS.RESOURCES.TOOLKIT.path,
          true
        ),
      "data-testid": "resourcesItem",
    },
    {
      key: "settings",
      label: NAVIGATION_ITEMS.SETTINGS.label,
      icon: <RooIcon icon={NAVIGATION_ITEMS.SETTINGS.icon} size="l" />,
      onClick: () => handleNavigationItemClick("Settings", NAVIGATION_ITEMS.SETTINGS.path),
      "data-testid": "settingsItem",
    },
    {
      key: "log-out",
      label: NAVIGATION_ITEMS.LOGOUT.label,
      icon: <RooIcon icon={NAVIGATION_ITEMS.LOGOUT.icon} size="l" />,
      onClick: clearAuthAndRedirect,
      "data-testid": "logOutItem",
    },
  ].filter((item) => item !== undefined);

  if (isSuperUser) {
    menuItems.push({
      key: "back-to-admin",
      label: NAVIGATION_ITEMS.ADMIN.BACK.label,
      icon: <RooIcon icon={NAVIGATION_ITEMS.ADMIN.BACK.icon} size="l" />,
      onClick: handleBackToAdmin,
      "data-testid": "backToAdminItem",
    });
  }

  return (
    <AccountDropdownContainer data-testid="accountDropdownMenu">
      <Dropdown
        menu={{ items: menuItems as ItemType[] }}
        trigger={["click"]}
        placement="bottomRight"
        onOpenChange={setIsOpen}
        dropdownRender={(menu) => (
          <AccountDropdownContainer data-testid="dropdownRender">{menu}</AccountDropdownContainer>
        )}
      >
        {children}
      </Dropdown>
    </AccountDropdownContainer>
  );
};

import React from "react";

import {
  Color,
  grayBase,
  grayLight400,
  redDark700,
  tealDark600,
  yellowDark700,
} from "../../styles/constants";
import { Button, ButtonProps } from "../Button";
import { Chip, ChipProps } from "../Chip";
import { DropDown, DropDownPropTypes } from "../DropDown";
import { Icon, IconProps } from "../Icon";

import {
  AlertActionArea,
  AlertLeftContainer,
  AlertMainContent,
  AlertRightContainer,
  Alert as AlertStyle,
  AlertText,
} from "./AlertStyles";

export interface AlertProps {
  alertBody?: React.ReactNode;
  actionButtonProps?: ButtonProps;
  $alertStatus?: "warning" | "error" | "info";
  avatar?: string;
  dropDownItems?: DropDownPropTypes["items"];
  customIconName?: IconProps["name"];
  $chipProps?: Omit<ChipProps, "children">;
  showDismiss?: boolean;
  onDismiss?: () => void;
}

const STATUS_COLORS: Record<NonNullable<AlertProps["$alertStatus"]>, Color> = {
  warning: yellowDark700,
  error: redDark700,
  info: tealDark600,
};

const ICONS: Record<NonNullable<AlertProps["$alertStatus"]>, IconProps["name"]> = {
  warning: "Error",
  error: "Error",
  info: "LightbulbOutline",
};

export const Alert: React.FC<AlertProps> = (props) => {
  const {
    alertBody,
    actionButtonProps,
    $alertStatus,
    avatar,
    dropDownItems,
    showDismiss,
    customIconName,
    $chipProps,
    onDismiss,
  } = props;

  const renderLeftContainerContent = () => {
    if (avatar) {
      return <div>{avatar}</div>;
    }
    if ($chipProps) {
      return <Chip {...$chipProps} />;
    }
    if (customIconName) {
      return <Icon name={customIconName} color={grayBase} />;
    }

    const iconName = $alertStatus ? ICONS[$alertStatus] : "Error";
    const iconColor = $alertStatus ? STATUS_COLORS[$alertStatus] : grayLight400;

    return <Icon name={iconName} color={iconColor} />;
  };

  return (
    <AlertStyle {...props}>
      <AlertMainContent>
        <AlertLeftContainer>
          {renderLeftContainerContent()}
          <AlertText>{alertBody}</AlertText>
        </AlertLeftContainer>
        <AlertActionArea>
          {actionButtonProps && (
            <Button {...actionButtonProps} $buttonType="primary" size="small" />
          )}
          {dropDownItems && (
            <DropDown items={dropDownItems}>
              <div>
                <Icon name="MoreHoriz" />
              </div>
            </DropDown>
          )}
        </AlertActionArea>
      </AlertMainContent>
      {showDismiss && (
        <AlertRightContainer>
          <Icon
            name="Close"
            onClick={onDismiss}
            color={grayBase}
            size="s"
            data-testid="alertDismissButton"
          />
        </AlertRightContainer>
      )}
    </AlertStyle>
  );
};

import {
  grayDarkest,
  grayBase,
  redBase,
  redLight50,
  SmallText,
  spacing,
  yellowBackground,
  greenBackground,
  FlexLayout,
  Color,
  SmallMediumBodyText,
} from "@RooUI";
import React from "react";
import styled, { css, keyframes } from "styled-components";

import { RooIcon } from "@RooBeta/components";
import { useToastStore } from "@RooBeta/store";
import { ToastRegion, ToastVariant } from "@RooBeta/store/useToastStore";
import { Icon } from "@RooBeta/types";

const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

const ToastContainer = styled.div<{ $isVisible: boolean; $region: string; $background: string }>`
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: ${spacing.m};

  ${({ $isVisible }) => css`
    animation: ${$isVisible ? slideUp : slideDown} 0.3s ease-in-out forwards;
  `}
  background: ${({ $background }) => $background};
`;

const Content = styled.div`
  flex: 1;
  padding: ${spacing.m};
`;

const Title = styled(SmallText)`
  ${SmallMediumBodyText}
`;

const getVariantStyles = (
  variant: ToastVariant
): { background: Color; text: Color; icon: Icon } => {
  switch (variant) {
    case "success":
      return { background: greenBackground, text: grayBase, icon: "approve" };
    case "error":
      return { background: redLight50, text: redBase, icon: "error" };
    case "info":
      return {
        background: yellowBackground,
        text: grayDarkest,
        icon: "info",
      };
  }
};

export const Toast = ({ activeRegion }: { activeRegion: ToastRegion }) => {
  const { isVisible, variant, title, message, region } = useToastStore();

  if (region !== activeRegion) return null;

  const variantStyles = getVariantStyles(variant);

  if (!isVisible) return null;

  return (
    <ToastContainer
      $isVisible={isVisible}
      $region={region}
      role="alert"
      $background={variantStyles.background}
    >
      <Content data-testid="toastContent">
        <FlexLayout gap="s">
          {variantStyles.icon && (
            <RooIcon
              icon={variantStyles.icon}
              size="l"
              data-testid={`rooIcon${variantStyles.icon}`}
            />
          )}
          <div>
            <Title data-testid="toastTitle" color={variantStyles.text}>
              {title}
            </Title>

            {typeof message === "string" ? (
              <SmallText data-testid="toastMessage" color={variantStyles.text}>
                {message}
              </SmallText>
            ) : (
              message
            )}
          </div>
        </FlexLayout>
      </Content>
    </ToastContainer>
  );
};
